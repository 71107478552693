import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import validateUrl from '@helpers/methods/validateUrl';

const SocialMediaLink = ({ link = '', spriteId = '' }) => {
  const isInvalidUrl = !validateUrl(link);
  if (isInvalidUrl) return null;

  return (
    <a href={link} target="_blank" rel="noreferrer noopener">
      <div className="social-media-icon">
        <SpriteIconViewer spriteId={spriteId} />
      </div>
    </a>
  );
};

export default SocialMediaLink;
