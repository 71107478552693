import React from 'react';
import SectionHeader from '@components/SectionHeader';
import ArticleCard from '@components/Cards/ArticleCard';
import ArrowButton from '@components/ArrowButton';

const AuthorContentCardPreview = ({
  header = 'Career advice',
  cardItems = [],
  moreLabel = 'View career advice',
  moreLink = '',
  linkRoot = '',
  isVisible = true,
}) => {
  return (
    <>
      {isVisible ? (
        <section className={'author-content-card-preview-section'}>
          <SectionHeader label={header} />
          <div className={'author-content-card-preview'}>
            {cardItems.map((cardItem, idx) => {
              const cardLink = `${linkRoot}${cardItem.slug}`;
              return <ArticleCard data={cardItem} key={idx} link={cardLink} />;
            })}
          </div>
          <ArrowButton link={moreLink} label={moreLabel} />
        </section>
      ) : null}
    </>
  );
};

export default AuthorContentCardPreview;
