import React from 'react';
import Image from '@components/Image';

const ProfileSnippet = ({
  supporterAvatar = null,
  supporterName = 'N/A',
  supporterOccupation = 'N/A',
}) => {
  return (
    <section className="profile-snippet-container">
      <div className="profile-snippet-avatar">
        <Image image={supporterAvatar} />
      </div>
      <div className="profile-snippet-details-container">
        <p className="details-name">{supporterName}</p>
        <p className="details-occupation">{supporterOccupation}</p>
      </div>
    </section>
  );
};

export default ProfileSnippet;
