import React from 'react';
import Button from 'antd/lib/button';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const WebsiteButton = ({ link = '' }) => {
  return (
    <>
      {link ? (
        <div className="website-button-container">
          <a
            className={'website-button-link'}
            target="_blank"
            rel="noopener noreferrer"
            href={link}
          >
            <Button className="website-button">
              <SpriteIconViewer spriteId={'new-tab'} />
              <span className="website-button-icon">Visit website</span>
            </Button>
          </a>
        </div>
      ) : null}
    </>
  );
};

export default WebsiteButton;
