import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import AuthorDetails from './AuthorDetails';
import ShareDropdown from './ShareDropdown';
import WebsiteButton from './WebsiteButton';
import AuthorAboutUs from './AuthorAboutUs';
import AuthorSupportMessage from './AuthorSupportMessage';
import AuthorContentCardPreview from './AuthorContentCardPreview';
import AuthorAds from './AuthorAds';
import isEmpty from 'lodash/isEmpty';
import VideoFrame from '@components/VideoFrame';
import Audio from '@components/Audio';
import AuthorBannerAds from './AuthorBannerAds';
import { encodeQueryParams, StringParam } from 'use-query-params';
import { stringify } from 'query-string';
import getAbsoluteURL from '@helpers/methods/getAbsoluteURL';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';

const Courses = ({ data, location, pageContext }) => {
  const authorPagesData = data.strapiAuthorPages;

  const audioExt = authorPagesData?.audio?.ext;
  const audioAbsoluteURL = getAbsoluteURL(
    authorPagesData?.audio?.publicURL,
    authorPagesData?.audio?.url
  );

  const careerAdvicesCardData = sortByPublishedDate(
    data.allStrapiCareerAdvices.nodes
  );

  const resourcesCardData = sortByPublishedDate(data.allStrapiResources.nodes);
  const metaTitle = `${authorPagesData?.company_name} on WanPNG`;
  const metaImage = authorPagesData?.metaImage
    ? authorPagesData?.metaImage?.localFile?.childImageSharp?.resize
    : null;

  const metaDescription = 'Author Page';
  const isCareerAdviceCardsVisible = !isEmpty(careerAdvicesCardData);
  const isResourcesCardsVisible = !isEmpty(resourcesCardData);

  const encodeAuthorURL = (author = '', rootPath = '') => {
    const encodedAuthor = encodeQueryParams(
      { category: StringParam },
      { author: author }
    );

    const urlPath = `${rootPath}`;
    const urlQueryParams = `?${stringify(encodedAuthor)}`;
    return `${urlPath}${urlQueryParams}`;
  };

  return (
    <Layout
      isMobileComingSoon={false}
      className="author-pages-template-layout _section-padding"
    >
      <SEO
        title={metaTitle}
        metaImage={metaImage}
        description={metaDescription}
        isLargeCard={false}
      />
      <div className="author-pages-container main-container">
        <div className="author-page-content">
          <AuthorDetails
            avatar={
              authorPagesData?.avatar?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            name={authorPagesData?.company_name}
            location={`${authorPagesData?.location?.city}, ${authorPagesData?.location?.province?.name}`}
            contact={authorPagesData?.company_contact}
            email={authorPagesData?.company_email}
            path={location?.pathname}
            twitterUrl={authorPagesData?.links?.twitter}
            facebookUrl={authorPagesData?.links?.facebook}
            linkedInUrl={authorPagesData?.links?.linkedIn}
            instagramUrl={authorPagesData?.links?.instagram}
          />

          <section className={'author-details-button-container'}>
            <ShareDropdown
              path={location?.pathname}
              currentLocationLink={location?.href}
            />
            <WebsiteButton link={authorPagesData?.links?.website} />
          </section>

          <Audio url={audioAbsoluteURL} ext={audioExt} />

          <AuthorAboutUs content={authorPagesData?.about_us} />

          <VideoFrame url={authorPagesData?.video_url} />

          <AuthorSupportMessage
            content={authorPagesData?.author_support?.support_message}
            supporterName={authorPagesData?.author_support?.name}
            supporterOccupation={authorPagesData?.author_support?.occupation}
            supporterAvatar={
              authorPagesData?.author_support?.avatar?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
          />
          <AuthorBannerAds
            adBanner={
              authorPagesData?.advertisement?.ad_banner?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
            adBannerLink={authorPagesData?.advertisement?.ad_banner_link}
          />
        </div>

        <AuthorAds
          adImage1={
            authorPagesData?.advertisement?.ad_space?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          adImage1Link={authorPagesData?.advertisement?.ad_space_link}
          adImage2={
            authorPagesData?.advertisement?.ad_space_2?.localFile
              ?.childImageSharp?.gatsbyImageData
          }
          adImage2Link={authorPagesData?.advertisement?.ad_space_2_link}
        />
      </div>

      <div className="author-pages-container">
        <AuthorContentCardPreview
          header={'Career advice'}
          cardItems={careerAdvicesCardData}
          linkRoot="/career-development/career-advice/"
          isVisible={isCareerAdviceCardsVisible}
          moreLabel={'View career advice'}
          moreLink={encodeAuthorURL(
            pageContext?.author,
            '/career-development/career-advice/results'
          )}
        />

        <AuthorContentCardPreview
          header={'Resources'}
          cardItems={resourcesCardData}
          linkRoot="/career-development/resources/"
          isVisible={isResourcesCardsVisible}
          moreLabel={'View resources'}
          moreLink={encodeAuthorURL(
            pageContext?.author,
            '/career-development/resources'
          )}
        />
      </div>
    </Layout>
  );
};

export default Courses;

export const query = graphql`
  query AuthorPagesQuery($id: Int!, $author: String) {
    strapiAuthorPages(strapiId: { eq: $id }) {
      metaImage: avatar {
        localFile {
          childImageSharp {
            resize(width: 200, height: 200, quality: 100) {
              src
              width
              height
            }
          }
        }
      }
      audio {
        publicURL
        url
        ext
      }
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
      }
      video_url
      company_name
      company_contact
      company_email
      location {
        city
        province {
          name
        }
      }
      links {
        facebook
        instagram
        linkedIn
        twitter
        website
      }
      about_us
      author_support {
        name
        occupation
        support_message
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                height: 60
                width: 60
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      advertisement {
        ad_space_link
        ad_space {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                height: 250
                width: 300
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
        }
        ad_space_2_link
        ad_space_2 {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                height: 250
                width: 300
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
        }
        ad_banner_link
        ad_banner {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                height: 250
                width: 970
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    allStrapiCareerAdvices(
      filter: {
        created_by: { username: { eq: $author } }
        content_status: { value: { eq: "2" } }
      }
      sort: { fields: published_date, order: DESC }
      limit: 2
    ) {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: FULL_WIDTH
                aspectRatio: 1
              )
            }
          }
        }
        category: career_advice_category {
          name
        }
        author: created_by {
          username
        }
        created_at(formatString: "MMMM D, YYYY")
        published_date
        published_at
        title
        slug
      }
    }
    allStrapiResources(
      filter: { created_by: { username: { eq: $author } } }
      sort: { fields: published_date, order: DESC }
      limit: 2
    ) {
      nodes {
        image: thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 100
                transformOptions: { cropFocus: CENTER }
                layout: FULL_WIDTH
                aspectRatio: 1
              )
            }
          }
        }
        author: created_by {
          username
        }
        created_at(formatString: "MMMM D, YYYY")
        published_date
        published_at
        title
        slug
      }
    }
  }
`;
