import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const copyToClipboard = (value) => () => {
  const el = document.createElement('textarea');
  el.value = value;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const CopyButton = ({
  href = 'http://www.wanpng.com/',
  label = 'Copy link',
  spriteId = 'link',
}) => {
  return (
    <a className={'share-button-container'} onClick={copyToClipboard(href)}>
      <SpriteIconViewer spriteId={spriteId} />
      <p>{label}</p>
    </a>
  );
};

export default CopyButton;
