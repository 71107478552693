import React from 'react';
import SectionHeader from '@components/SectionHeader';
import MarkdownViewer from '@components/MarkdownViewer';

const AuthorAboutUs = ({ content = '' }) => {
  return (
    <section className="author-about-us-container">
      <SectionHeader className="author-about-us-header" label={'About us'} />
      <MarkdownViewer className="author-about-us-content" source={content} />
    </section>
  );
};

export default AuthorAboutUs;
