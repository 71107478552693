import React from 'react';
import SectionHeader from '@components/SectionHeader';
import MarkdownViewer from '@components/MarkdownViewer';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import ProfileSnippet from '@templates/AuthorPagesViewer/AuthorSupportMessage/ProfileSnippet';
import isEmpty from 'lodash/isEmpty';

const AuthorSupportMessage = ({
  content = '',
  supporterAvatar = null,
  supporterName = '',
  supporterOccupation = '',
}) => {
  const isInvalidData =
    isEmpty(content) ||
    isEmpty(supporterAvatar) ||
    isEmpty(supporterName) ||
    isEmpty(supporterOccupation);

  if (isInvalidData) return null;

  return (
    <section className="author-support-message-container">
      <SectionHeader
        className="author-support-message-header"
        label={'Why are we supporting Wan PNG?'}
      />

      <div className="author-support-icon-container">
        <SpriteIconViewer spriteId={'quote'} />
      </div>
      <MarkdownViewer
        className={'author-support-message-content-container'}
        source={content}
      />
      <ProfileSnippet
        supporterAvatar={supporterAvatar}
        supporterName={supporterName}
        supporterOccupation={supporterOccupation}
      />
    </section>
  );
};

export default AuthorSupportMessage;
