import React from 'react';
import Image from '@components/Image';
import isEmpty from 'lodash/isEmpty';

const AuthorBannerAds = ({ adBanner = '', adBannerLink = '' }) => {
  const isInvalidData = isEmpty(adBanner) || isEmpty(adBannerLink);

  if (isInvalidData) return null;

  return (
    <div className="author-ads-banner">
      <a
        className="ads-banner-link"
        href={adBannerLink}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="ads-banner">
          <Image image={adBanner} />
        </div>
      </a>
    </div>
  );
};

export default AuthorBannerAds;
