import React from 'react';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import ShareButton from '@templates/AuthorPagesViewer/ShareDropdown/ShareButton';
import CopyButton from '@templates/AuthorPagesViewer/ShareDropdown/CopyButton';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const ShareDropdown = ({ path = '', currentLocationLink = '' }) => {
  const menu = (
    <Menu className="share-dropdown-menu-container">
      <Menu.Item>
        <ShareButton
          href={`https://www.facebook.com/dialog/share?app_id=${process.env.GATSBY_FB_APP_ID}&display=popup&href=${process.env.GATSBY_SITE_URL}${path}&redirect_uri=https://www.facebook.com`}
          label={'Facebook'}
          spriteId={'facebook_colored'}
        />
      </Menu.Item>
      <Menu.Item>
        <ShareButton
          href={`https://twitter.com/intent/tweet?&url=${process.env.GATSBY_SITE_URL}${path}`}
          label={'Twitter'}
          spriteId={'twitter_colored'}
        />
      </Menu.Item>
      <Menu.Item>
        <ShareButton
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.GATSBY_SITE_URL}${path}`}
          label={'LinkedIn'}
          spriteId={'linkedin_colored'}
        />
      </Menu.Item>
      <Menu.Item>
        <CopyButton
          href={currentLocationLink}
          label={'Copy link'}
          spriteId={'link'}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      className="share-dropdown-container"
      overlay={menu}
      placement="bottomCenter"
      trigger={['click']}
    >
      <Button>
        <SpriteIconViewer spriteId={'reply'} />
        <span className="share-dropdown-label">Share profile</span>
      </Button>
    </Dropdown>
  );
};

export default ShareDropdown;
