import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import Image from '@components/Image';
import SocialMediaLink from './SocialMediaLink.jsx';

const AuthorDetails = ({
  avatar = '',
  name = '',
  location = '',
  contact = '',
  email = '',
  twitterUrl = '',
  facebookUrl = '',
  linkedInUrl = '',
  instagramUrl = '',
  path = '',
}) => {
  const formatContactNo = (value = '') => {
    const isValueString = typeof value === 'string';
    const isValueNumber = typeof value === 'number';
    const isValueInvalid = !isValueString && !isValueNumber;
    if (isValueInvalid) return;
    const contactNo = typeof value === 'string' ? value : value.toString();

    const spacedContactNo = contactNo.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1 $2 $3'
    );
    return `+${spacedContactNo}`;
  };

  return (
    <section className="author-info-section">
      <div className="author-avatar image-container">
        {avatar ? (
          <Image image={avatar} />
        ) : (
          <SpriteIconViewer
            className={'default-author-icon'}
            spriteId={'default_author_icon'}
          />
        )}
      </div>

      <div className="author-details">
        <h1 className="details-name"> {name}</h1>

        <div className="info details-location">
          <div className="author-details-icon">
            <SpriteIconViewer
              className={'location-icon'}
              spriteId={'location_on'}
            />
          </div>
          <p>{location}</p>
        </div>

        <div className="info details-contact">
          <div className="author-details-icon">
            <SpriteIconViewer
              className={'call-icon'}
              spriteId={'call-filled'}
            />
          </div>
          <p>{formatContactNo(contact)}</p>
        </div>

        <div className="info details-email ">
          <a
            href={`mailto:?subject=${`${name} Author Page`}&body=${
              process.env.GATSBY_SITE_URL
            }${path}`}
            rel="noreferrer noopener"
          >
            <div className="author-details-icon">
              <SpriteIconViewer
                className={'email-icon'}
                spriteId={'email-filled'}
              />
            </div>
            <p>{email}</p>
          </a>
        </div>

        <div className={'social-media-collection'}>
          <SocialMediaLink link={twitterUrl} spriteId={'twitter_colored'} />
          <SocialMediaLink link={linkedInUrl} spriteId={'linkedin_colored'} />
          <SocialMediaLink link={facebookUrl} spriteId={'facebook_colored'} />
          <SocialMediaLink link={instagramUrl} spriteId={'instagram_colored'} />
        </div>
      </div>
    </section>
  );
};

export default AuthorDetails;
