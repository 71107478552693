import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const ShareButton = ({
  href = 'http://www.alipay.com/',
  label = 'Facebook',
  spriteId = 'facebook',
}) => {
  return (
    <a
      className={'share-button-container'}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      <SpriteIconViewer spriteId={spriteId} />
      <p>{label}</p>
    </a>
  );
};

export default ShareButton;
