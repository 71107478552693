import React from 'react';
import { Link } from 'gatsby';
import Button from 'antd/lib/button';
import IconArrowForward from '@components/Icons/IconArrowForward';

const ArrowButton = ({ label = 'View all', link = '/news' }) => {
  return (
    <div className="arrow-button-container">
      <Link className="arrow-button-link" to={link}>
        <Button className="arrow-button">
          <span className="arrow-button-label">{label}</span>
          <IconArrowForward />
        </Button>
      </Link>
    </div>
  );
};

export default ArrowButton;
