import React from 'react';
import Image from '@components/Image';
import isEmpty from 'lodash/isEmpty';

const AuthorAboutUs = ({
  adImage1 = null,
  adImage2 = '',
  adImage1Link = '',
  adImage2Link = '',
}) => {
  const isAd1Invalid = isEmpty(adImage1) || isEmpty(adImage1Link);
  const isAd2Invalid = isEmpty(adImage2) || isEmpty(adImage2Link);
  const areAdsInvalid = isAd1Invalid && isAd2Invalid;

  const renderAd1 = () => {
    if (isAd1Invalid) return null;
    return (
      <a
        className="author-ads link"
        href={adImage1Link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="author-ads">
          <Image image={adImage1} />
        </div>
      </a>
    );
  };

  const renderAd2 = () => {
    if (isAd2Invalid) return null;
    return (
      <a
        className="author-ads"
        href={adImage2Link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <div className="author-ads">
          <Image image={adImage2} />
        </div>
      </a>
    );
  };

  if (areAdsInvalid) return null;

  return (
    <section className="author-ads-container">
      {renderAd1()}
      {renderAd2()}
    </section>
  );
};

export default AuthorAboutUs;
